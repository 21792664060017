import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import Login from "./components/login";
import NavBar from "./view/navbar";

console.log("inasdsad");
const PrivateRoute = () => {
    console.log("inasdsad");
    const isAuth = sessionStorage.getItem('isAuthenticated');
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    if (!isAuthenticated) {
        localStorage.clear();
    }
    return (isAuthenticated
        ? <>
                <div id="wrapper">
                    <NavBar />
                    <div id="main-content">
                        <Outlet />
                    </div>
                </div>
        </>
        : <Navigate to="/login" />);
};
//(props) => (!isAuthenticated ? <Navigate to="/login" replace={true} /> : <Component {...props} />)


const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(PrivateRoute);