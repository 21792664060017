import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import { Tree, Tabs } from 'antd';
import {
    categoryData,
    categorySelector,
} from "../../store/reducer/category";
import {
    subcategoryData,
    subcategoryFilterData,
    subCategorySelector,
} from "../../store/reducer/subcategory";
import {
    itemsizeData,
    itemsizeSelector,
} from "../../store/reducer/itemsize";
import {
    productData,
    productsSelector,
} from "../../store/reducer/products";
import {
    clearData,
    sortingUpdate,
    sortingSelector,
} from "../../store/reducer/sorting";
import _, { filter } from 'lodash';
import { API_STATUS } from "../../utils/constants";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { assestURL } from "../../services/config";
import Select from "react-dropdown-select";
function Sorting() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { productDatas, productCount } = useSelector(productsSelector);
    const { categoryDatas, categoryCount } = useSelector(categorySelector);
    const { itemsizeDatas, itemsizeCount } = useSelector(itemsizeSelector);
    const { subcategoryDatas, subcategoryCount } = useSelector(subCategorySelector);
    const { updateloading, errorMessage } = useSelector(sortingSelector);
    const [treeData, setTreeData] = useState([]);
    const [subcatgtreeData, setSubcatgtreeData] = useState([]);
    const [producttreeData, setProducttreeData] = useState([]);
    const x = 3;
    const y = 2;
    const z = 1;
    const defaultData = [];
    const [catgId, setCatgID] = useState([]);
    const [subcatgId, setSubcatgID] = useState([]);
    const [tab, setTab] = useState(0);

    const buildData = (datas, type) => {
        let builtdatas = []
        let datas1 = datas.map((catg) => {
            builtdatas.push({ id: catg.id, name: catg.name, image: catg.image, order_no: catg.order_no });
        })
        return _.orderBy(builtdatas, ['order_no'], ['asc']);
    };

    const submitsort = (e, index) => {
        let postData = { category: [], subcategory: [], product: [] };
        if (index == 1) {
            postData = { category: treeData, subcategory: [], product: [] };
        }
        if (index == 2) {
            postData = { category: [], subcategory: subcatgtreeData, product: [] };
            if (subcatgtreeData.length == 0)
                return false;
        }
        if (index == 3) {
            postData = { category: [], subcategory: [], product: producttreeData };
            if (producttreeData.length == 0)
                return false;
        }

        console.log(postData)
        dispatch(sortingUpdate({ postData }))
    };

    const onSortEnd = (oldIndex, newIndex) => {
        setTreeData((array) => arrayMoveImmutable(array, oldIndex, newIndex));
    };

    const onSortSubcatEnd = (oldIndex, newIndex) => {
        setSubcatgtreeData((array) => arrayMoveImmutable(array, oldIndex, newIndex));
    };

    const onSortProductEnd = (oldIndex, newIndex) => {
        setProducttreeData((array) => arrayMoveImmutable(array, oldIndex, newIndex));
    };

    const DropdownChange = (value, index) => {
        let datas = [];
        let filtered_data = [];
        if (value.length > 0) {
            if (index == 1) {
                setCatgID(value);
                if (subcategoryDatas && subcategoryDatas.length > 0) {
                    filtered_data = _.filter(subcategoryDatas, { category_id: value[0].id });
                    console.log(filtered_data, "filtered_data")
                    datas = buildData(filtered_data, 2);
                    setSubcatgtreeData(datas);
                    setProducttreeData([]);
                }
            } else if (index == 2) {
                setSubcatgID(value);
                if (productDatas && productDatas.length > 0) {
                    filtered_data = _.filter(productDatas, { subcategory_id: value[0].id });
                    console.log(filtered_data, "filtered_data")
                    datas = buildData(filtered_data, 3);
                    setProducttreeData(datas);
                }

            }
        }
    }

    useEffect(() => {
        dispatch(categoryData({}));
        dispatch(subcategoryData({}));
        dispatch(itemsizeData({}));
        dispatch(productData({}));
        console.log(process.env.REACT_APP_TITLE, "env");
    }, []);

    useEffect(() => {
        if (categoryDatas) {
            let datas = buildData(categoryDatas, 1);
            setTreeData(datas);
        }
        console.log(categoryDatas, "categoryDatas")
        console.log(subcategoryDatas, "subcategoryDatas")
        console.log(itemsizeDatas, "itemsizeDatas")
        console.log(productDatas, "productDatas")
    }, [categoryDatas]);

    useEffect(() => {
        console.log(subcatgtreeData, "subcatgtreeData")

    }, [subcatgtreeData])

    useEffect(() => {
        if (updateloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Updated Successfully!");
            dispatch(clearData())
            dispatch(categoryData({}));
            dispatch(subcategoryData({}));
            dispatch(productData({}));
            if (catgId.length > 0) {
                DropdownChange(catgId)
            }
            if (subcatgId.length > 0) {
                DropdownChange(subcatgId)
            }
            // setSubcatgtreeData([]);
            // setProducttreeData([]);
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }

        }
    }, [updateloading, errorMessage])

    const tabClick = (key, event) => {
        console.log(key);
        setCatgID([]); setSubcatgID([]); setSubcatgtreeData([]); setProducttreeData([]);
    };
    return (
        <>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Sorting"
                    Breadcrumb={[{ name: "Sorting" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Sorting</h2>
                                    </div>
                                    <div className="col-md-6 row">

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="col-md-12">
                                <Tabs defaultActiveKey="1" onTabClick={tabClick}>
                                    <Tabs.TabPane tab="Sort Category" key="1">

                                        <div className="card p-5">
                                            <SortableList
                                                onSortEnd={onSortEnd}
                                                className="list"
                                                draggedItemClassName="dragged"
                                            >
                                                {treeData && treeData.map((item) => (
                                                    <SortableItem key={item.id}>
                                                        <div className="item">
                                                            <img src={assestURL + item.image} />
                                                            <p>{item.name}</p></div>
                                                    </SortableItem>
                                                ))}
                                            </SortableList>
                                            <br />
                                            <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitsort(e, 1); }}>Save Sorted datas</button>
                                        </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Sort Subcategory" key="2">

                                        <div className="card p-5">
                                            <div className="col-md-4">
                                                <Select
                                                    className=""
                                                    options={(categoryDatas) ? categoryDatas : []}
                                                    values={catgId}
                                                    disabled={false}
                                                    placeholder={"Select Category"}
                                                    multi={false}
                                                    dropdownHandle={true}
                                                    searchable={false}
                                                    labelField={"name"}
                                                    valueField={"id"}
                                                    dropdownHeight="300px"
                                                    onChange={(value) => { DropdownChange(value, "1"); }}
                                                /></div>
                                            <br />

                                            <SortableList
                                                onSortEnd={onSortSubcatEnd}
                                                className="list"
                                                draggedItemClassName="dragged"
                                            >
                                                {subcatgtreeData && subcatgtreeData.map((item) => (
                                                    <SortableItem key={item.id}>
                                                        <div className="item">
                                                            <img src={assestURL + item.image} />
                                                            <p>{item.name}</p></div>
                                                    </SortableItem>
                                                ))}
                                            </SortableList>
                                            <br />
                                            <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitsort(e, 2); }}>Save Sorted datas</button>
                                        </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Sort Products" key="3">

                                        <div className="card p-5">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <Select
                                                        className=""
                                                        options={(categoryDatas) ? categoryDatas : []}
                                                        values={catgId}
                                                        disabled={false}
                                                        placeholder={"Select Category"}
                                                        multi={false}
                                                        dropdownHandle={true}
                                                        searchable={false}
                                                        labelField={"name"}
                                                        valueField={"id"}
                                                        dropdownHeight="300px"
                                                        onChange={(value) => { DropdownChange(value, "1"); }}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <Select
                                                        className=""
                                                        options={(subcatgtreeData) ? subcatgtreeData : []}
                                                        values={subcatgId}
                                                        disabled={false}
                                                        placeholder={"Select Subcategory"}
                                                        multi={false}
                                                        dropdownHandle={true}
                                                        searchable={false}
                                                        labelField={"name"}
                                                        valueField={"id"}
                                                        dropdownHeight="300px"
                                                        onChange={(value) => { DropdownChange(value, "2"); }}
                                                    /></div>
                                            </div><br />
                                            <SortableList
                                                onSortEnd={onSortProductEnd}
                                                className="list"
                                                draggedItemClassName="dragged"
                                            >
                                                {producttreeData && producttreeData.map((item) => (
                                                    <SortableItem key={item.id}>
                                                        <div className="item">
                                                            <img src={assestURL + item.image} />
                                                            <p>{item.name}</p></div>
                                                    </SortableItem>
                                                ))}
                                            </SortableList><br />
                                            <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitsort(e, 3); }}>Save Sorted datas</button>
                                        </div>
                                    </Tabs.TabPane>
                                    {/* <Tabs.TabPane tab="Sort Itemsize" key="4">
                                        <Tree
                                            className="draggable-tree"
                                            defaultExpandedKeys={expandedKeys}
                                            draggable
                                            blockNode
                                            loadData={onLoadData}
                                            onDragEnter={onDragEnter}
                                            onDragOver={onDragOver}
                                            onDrop={onDrop}
                                            treeData={treeData}
                                        />
                                    </Tabs.TabPane> */}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}
export default Sorting;